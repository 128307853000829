<template>
    <Modal v-model="showModal" @update:modelValue="redirect">
        {{ modalText }}
    </Modal>
</template>


<script>
import { reactive } from '@vue/reactivity'
import { ref } from 'vue'
import api from '@/services/auth/auth.service.js'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
// import { getValidationError, clearValidationError } from '@/helpers/objects'
import { notify } from "@kyvg/vue3-notification"
import Modal from '@/components/elements/Modal'

export default {
    props: [''],
    components: {
        Modal
    },
    setup() {
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const showModal = ref(false)
        const modalText = ref('')

        store.commit('setField', {
            field: 'loading', value: true,
        })

        verifyEmail()

        async function verifyEmail() {
            const url = route.params.path.split('quoting-portal/')[1]
            const signature = route.params.signature
            const result = await api.verifyEmail(url, {signature})
            store.commit('setField', {
                field: 'loading', value: false,
            })
            if(result.message) {
                modalText.value = result.message
                showModal.value = true
                localStorage.removeItem('user')
            } else redirect()
        }

        function redirect() {
            router.push('/login')
        }

        return {
            showModal,
            modalText,
            redirect,
        }
    }
}
</script>
